<template>
  <div class="cloud-group">
    <div class="group-wrap" :class="{'open': open}" v-if="group && group.length > 1">
      <div class="folder-item" @click="open = !open">
        <span class="status success" v-if="status == 'success'"></span>
        <span class="status warning" v-if="status == 'warning'"></span>
        <span class="status error" v-if="status == 'error'"></span>
        <img class="icon" src="@/assets/filetype/filetype_folder.png" alt="">
        <div class="file-info">
         <p class="name van-ellipsis">{{parentPathName}}</p>
         <p class="meta-info">{{group[0].Task.CreatedAt | formatDate}}·{{group.length}}个文件·{{ groupSize | bytesToSize }}</p>
        </div>
      </div>
      <div class="folder-wrap" v-if="open">
        <cloud-item 
          class="group-item" 
          :parentPath="parentPath"
          v-for="item in group" 
          :key="item.Task.TaskFileId" 
          :item="item"
          @refresh="refresh"
        />
      </div>
    </div>
    <template v-if="group && group.length == 1">
      <cloud-item 
        class="file-item" 
        :item="group[0]" 
        :parentPath="parentPath"
        @refresh="refresh"
      />
    </template>
  </div>
</template>

<script>
import CloudItem from '@/components/CloudItem.vue'
export default {
  props: ['group'],
  data () {
    return {
      open: false,
    }
  },
  components: {
    CloudItem
  },
  computed: {
    parentPath () {
      if (this.group.length > 1) {
        let maxPath = 0
        const pathArr = this.group.map(item => {
          const temp = item.Task.Path.split('/')
          temp.shift()
          maxPath = Math.max(maxPath, temp.length)
          return temp
        })

        let tempPathArr = []
        let path = pathArr[0][0]
        let findParentPath = false
        for (let i = 0; i < maxPath; i++) {
          if (findParentPath) {
            break
          }
          for (let j = 0; j < pathArr.length; j++) {
            if (pathArr[j][i] != path) {
              findParentPath = true
              break
            } 

            if (j == pathArr.length - 1) {
              tempPathArr.push(path)
              path = pathArr[0][i+1]
            } 
          }
        }
        return `/${tempPathArr.join('/')}`
      } else {
        return ''
      }
    },
    groupSize () {
      let size = 0
      this.group.forEach(item => {
        size = size + item.Task.Size
      })
      return size
    },
    parentPathName () {
      const arr = this.parentPath.split('/')
      return arr[arr.length - 1]
    },
    status () {
      let status = 'success'
      for (let i = 0; i < this.group.length; i++) {
        const item = this.group[i]
        if (item.Task.Status == this.OFFLINE_TASK_STATUS.FAILED) {
          status = 'error'
          break
        } else if (item.Task.Status != this.OFFLINE_TASK_STATUS.FINISHED) {
          status = 'warning'
        }
      }
      return status
    }
  },
  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.cloud-group {
  cursor: pointer;
  margin-top: 6px;
  &:first-child {
    margin-top: 0;
  }
}
.group-wrap {
  &.open {
    background-color: #fff;
    .folder-wrap {
      padding-bottom: 5px;
      .group-item {
        background-color: #f8f8f8;
      }
    }
  }
  .folder-wrap {
    padding-left: 20px;
    .group-item {
      zoom: 0.9;
      margin-top: 5px;
      padding: 5px 6px;
      padding-right: 15px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
    }
  }
}
.folder-item {
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #eee;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    background-color: rgba($theme-color, 0.1);
    border-color: rgba($theme-color, 0.5);
  }
  .status {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 6px;
    display: inline-block;
    &.success {
      background-color: $success-color;
    }
    &.warning {
      background-color: $warning-color;
    }
    &.error {
      background-color: $danger-color;
    }
  }

  .icon {
    height: 24px;
    margin-right: 6px;
  }

  .file-info {
    flex: auto;
    overflow: hidden;
    .name {
      font-size: 13px;
      margin-bottom: 2px;
    }
    .meta-info {
      color: #999;
    }
  }
}

.file-item {
  border: 1px solid #eee;
}
</style>