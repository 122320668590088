<template>
  <div class="cloud-item" v-if="item">
    <img class="icon" :src="require(`@/assets/filetype/${filetype}.png`)" alt="">
    <div class="file-info">
      <p class="name van-ellipsis">{{filename}}</p>
      <div class="meta-info">
        <p class="left">{{task.CreatedAt | formatDate}}·{{task.Size | bytesToSize}}</p>
      </div>
    </div>
    <div class="status theme" v-if="status == OFFLINE_TASK_STATUS.DOWNLOADING">
      <i class="el-icon-loading icon"></i>
      <span>离线中({{((item.FinishedSize / task.Size) * 100).toFixed(0)}}%)</span>
    </div>
    <div class="status success" v-if="status == OFFLINE_TASK_STATUS.FINISHED" @click="downloadTask(task)">
      <img src="@/assets/icons/smile_success.png" class="icon">
      <span>可高速下载</span>
    </div>
    <div class="status warning" v-if="status == OFFLINE_TASK_STATUS.WAITING || status == OFFLINE_TASK_STATUS.WAITING_TORRENT">
      <i class="el-icon-time icon"></i>
      <span>排队中</span>
    </div>
    <div class="status danger" v-if="status == OFFLINE_TASK_STATUS.CANCELED">
      <i class="el-icon-error icon"></i>
      <span>已取消</span>
    </div>
    <div class="status danger" v-if="status == OFFLINE_TASK_STATUS.FAILED">
      <img src="@/assets/icons/smile_error.png" class="icon">
      <span>加速失败</span>
    </div>
    <div class="operation-wrap">
      <van-icon @click="downloadTask(task)" class="theme rotate" v-if="status == OFFLINE_TASK_STATUS.FINISHED" name="upgrade" />
      <van-icon @click="retryTask(task.PsFileTaskId)" class="danger" v-if="status == OFFLINE_TASK_STATUS.FAILED" name="replay" />
    </div>
  </div>
</template>


<script>
import { getFileType } from '@/util'
import { retryTask } from '@/api/user'

export default {
  props: ['item', 'parentPath'],
  computed: {
    task () {
      return this.item && this.item.Task
    },
    filename () {
      if (this.task) {
        const arr = this.task.Path.split(`${this.parentPath}/`)
        return arr[arr.length - 1]
      }
      return ''
    },
    parentPathName () {
      if (this.parentPath) {
        const arr = this.parentPath.split('/')
        return arr[arr.length - 1]
      } else {
        return ''
      }
    },
    status () {
      return this.task && this.task.Status
    },
    filetype () {
      return getFileType(this.filename)
    },
  },
  methods: {
    async retryTask (taskid) {
      const res = await retryTask({ taskid })
      if (res.code == 0) {
        this.$emit('refresh')
        return this.$toast.success('重试成功')
      } else {
        return this.$toast.success('重试失败，请稍后尝试')
      }
    },
    downloadTask (item) {
      let filename = this.filename
      if (this.parentPathName) {
        filename = `${this.parentPathName}/${filename}`
      }

      this.openWindow(`fsx://task/download?id=${item.PsFileTaskId}&size=${item.Size}&offlineid=${item.OfflineId}&filename=${encodeURIComponent(filename)}`)
      this.checkClientInstall()
      // setTimeout(() => {
      //   this.showDownloadDialog()
      // }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';
.cloud-item {
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  transition: all ease 0.1s;
  .icon {
    height: 24px;
    margin-right: 6px;
  }
  .file-info {
    flex: auto;
    overflow: hidden;
    .name {
      color: #666;
      margin-bottom: 2px;
    }
    .meta-info {
      color: #999;
    }
  }
  .meta-info {
    display: flex;
    justify-content: space-between;
  }
  .status {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .icon {
      height: 14px;
      margin-right: 2px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .operation-wrap {
    .rotate {
      transform: rotate(180deg);
    }
    .van-icon {
      display: inline-block;
      font-size: 18px;
      margin-left: 10px;
    }
  }
}
</style>