<template>
   <div class="task-wrap">
      <div class="inner">
        <template v-if="groupCloudList.length > 0">
          <cloud-group
            class="cloud-group"
            v-for="group in groupCloudList"
            :key="group.id"
            :group="group.Files"
            @refresh="refresh"
          />
        </template>
        <van-empty v-if="groupCloudList.length == 0" description="暂无预加速任务">
          <el-button 
            type="primary" 
            size="mini"
            class="no-drag"
            icon="el-icon-plus"
            @click="showUploadDialog"
          >现在添加任务
          </el-button>
        </van-empty>
      </div>
    </div>
</template>

<script>
import { getTaskList } from '@/api/user'
import CloudGroup from '@/components/CloudGroup'
export default {
  name: 'task',
  data () {
    return {
      timer: null,
      groupCloudList: []
    }
  },
  components: { CloudGroup },
  async created () {
    await this.scheduleGetTaskList()
  },
  destroyed () {
    this.timer && clearInterval(this.timer)
  },
  methods: {
    async refresh () {
      await this.scheduleGetTaskList()
    },
    async scheduleGetTaskList () {
      this.timer && clearInterval(this.timer)
      const res = await getTaskList()
      if (res.code == 0) {
        this.groupCloudList = res.data.List
      }
      this.timer = setTimeout(async () => {
        await this.scheduleGetTaskList()
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-wrap {
  padding: 10px 20px;
}  
</style>
